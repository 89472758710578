import { ErrorAction, IAction, VoidAction } from "../../app/types";
import { CONTACT_FORM__SEND_MESSAGE_EVENT, CONTACT_FORM__SEND_MESSAGE_EVENT_FAILED, CONTACT_FORM__SEND_MESSAGE_EVENT_STARTED, CONTACT_FORM__SEND_MESSAGE_EVENT_SUCCESS } from "./constants";
import { SendMessageRequest } from "./types";

export interface SendMessageEventAction extends IAction {
    values: SendMessageRequest
};

export const sendMessageEvent = (values: SendMessageRequest) : SendMessageEventAction => ({
    type: CONTACT_FORM__SEND_MESSAGE_EVENT,
    values
});

export const sendMessageEventStarted = (): VoidAction => ({
    type: CONTACT_FORM__SEND_MESSAGE_EVENT_STARTED
});

export const sendMessageEventFailed = (error: string): ErrorAction => ({
    type: CONTACT_FORM__SEND_MESSAGE_EVENT_FAILED,
    error
});

export const sendMessageEventSuccess = (): VoidAction => ({
    type: CONTACT_FORM__SEND_MESSAGE_EVENT_SUCCESS
});