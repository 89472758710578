import { FunctionComponent } from "react";

export enum RouteType {
    Home = "Home",
    Club = "Club",
    Clubs = "Clubs",
    Coach = "Coach",
    Coaches = "Coaches",
    Contact = "Contact",
    League = "League",
    Leagues = "Leagues",
    Player = "Player",
    Players = "Players"
}

export interface Route {
    type: RouteType,
    path: string,
    Page: FunctionComponent<{}>,
    children?: Route[]
}