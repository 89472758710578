import { ErrorAction, IAction, VoidAction } from "../../../app/types";
import { League } from "../list/types";
import { LEAGUES__GET_LEAGUE_DETAILS, LEAGUES__GET_LEAGUE_DETAILS_FAILED, LEAGUES__GET_LEAGUE_DETAILS_STARTED, LEAGUES__GET_LEAGUE_DETAILS_SUCCESS } from "./constants";


export interface GetLeagueDetailsAction extends IAction {
    season: string,
    id: string
};

export interface GetLeagueDetailsActionSuccess extends IAction {
    data: League;
};

export const getLeagueDetails = (season: string, id: string): GetLeagueDetailsAction => ({
    type: LEAGUES__GET_LEAGUE_DETAILS,
    season,
    id
});

export const getLeagueDetailsStarted = (): VoidAction => ({
    type: LEAGUES__GET_LEAGUE_DETAILS_STARTED
});

export const getLeagueDetailsFailed = (error: string): ErrorAction => ({
    type: LEAGUES__GET_LEAGUE_DETAILS_FAILED,
    error
});

export const getLeagueDetailsSuccess = (data: League): GetLeagueDetailsActionSuccess => ({
    type: LEAGUES__GET_LEAGUE_DETAILS_SUCCESS,
    data
});