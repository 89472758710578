import { Card, CardGroup, Col, Row } from "react-bootstrap";

const Home = () => {
  
    return (
        <Row>
            <Col>
        {/*<Col xs={9}>*/}
                <CardGroup>
                    <Card>
                        <Card.Img variant="top" src="images/volleyball.jpg" />
                        <Card.Body>
                            <Card.Title>Baza klubów</Card.Title>
                            <Card.Text>
                                Ponad 1500 klubów siatkarskich z najpopularniejszych lig i nie tylko!
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-muted">Odwiedź zakładkę "Kluby"!</small>
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src="images/volleyball2.jpg" />
                        <Card.Body>
                            <Card.Title>Baza zawodników</Card.Title>
                            <Card.Text>
                                Ponad 5500 zawodników z niemal 100 krajów, wraz z najważniejszymi informacjami!
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-muted">Odwiedź zakładkę "Zawodnicy"!</small>
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src="images/volleyball3.jpg" />
                        <Card.Body>
                            <Card.Title>Baza trenerów</Card.Title>
                            <Card.Text>
                                A także ponad 700 szkoleniowców z niemal 50 krajów.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-muted">Odwiedź zakładkę "Trenerzy"!</small>
                        </Card.Footer>
                    </Card>
                </CardGroup>
        </Col>
        {/*<Col xs={3}>*/}
        {/*  Menu boczne*/}
        {/*</Col>*/}
      </Row>
    );
  }
  
  export default Home;
  