import { CountryFlagProps } from './types';

const CountryFlag = (props: CountryFlagProps) => {

    const { countryCode } = props;

        return (
            <span className={`flag fi fi-${countryCode}`}></span>
        );
}

export default CountryFlag;