import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { RequestStatus } from "../../../api/types";
import { genericSearch } from "../../../functions/genericSearch";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import CardElement from "../../../modules/CardElement/CardElement";
import LoadingComponent from "../../../modules/LoadingComponent/LoadingComponent";
import SearchInput from "../../../modules/SearchInput/SearchInput";
import { getPlayers } from "./actions";
import { Player } from "./types";


const Players = () => {
    const dispatch = useAppDispatch();
    const players = useAppSelector(state => state.players.data);
    const status = useAppSelector(state => state.players.status);

    const [query, setQuery] = useState<string>("");

    useEffect(() => {
      dispatch(getPlayers())
    }, [dispatch]);

    const filterPlayers = players
        .filter((player) =>
            genericSearch<Player>(player, ["name", "surname", "country"], query)
    );

    if (status === RequestStatus.InProgress) return <LoadingComponent />;

    else return (
      <>
        <SearchInput onChangeSearchQuery={(query) => setQuery(query)} placeholder="Wyszukaj zawodnika po nazwie lub kraju..." />
          <Row>
          {filterPlayers.map(player =>
            <CardElement countryCode={player.countryCode} link={`/players/${player.id}`} name={`${player.name} ${player.surname}`} />
          )}
          </Row> 
        </>
    )
}

export default Players;