import { ErrorAction, IAction, VoidAction } from "../../../app/types";
import { COACHES__GET_COACHES, COACHES__GET_COACHES_FAILED, COACHES__GET_COACHES_STARTED, COACHES__GET_COACHES_SUCCESS } from "./constants";

import { Coach } from "./types";

export interface GetCoachesAction extends IAction {};

export interface GetCoachesSuccessAction extends IAction {
    data: Coach[];
};

export const getCoaches = (): VoidAction => ({
    type: COACHES__GET_COACHES
});

export const getCoachesStarted = (): VoidAction => ({
    type: COACHES__GET_COACHES_STARTED
});

export const getCoachesFailed = (error: string): ErrorAction => ({
    type: COACHES__GET_COACHES_FAILED,
    error
});

export const getCoachesSuccess = (data: Coach[]): GetCoachesSuccessAction => ({
    type: COACHES__GET_COACHES_SUCCESS,
    data
});