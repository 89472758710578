import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../api/api";
import { GetPlayersResponse } from "../../../api/responses";
import { FetchResult } from "../../../api/types";
import { getPlayersFailed, getPlayersStarted, getPlayersSuccess } from "./actions";
import { PLAYERS__GET_PLAYERS } from "./constants";

import { Player } from "./types";

function* onGetPlayers() {
    
    yield put(getPlayersStarted());

    let response : FetchResult<GetPlayersResponse> = {}

    response = yield call(api.playersList);

    const data = response.data ?? [];

    if (response.error) {
        yield put(getPlayersFailed(response.error));
    }

    yield put(getPlayersSuccess(data as Player[]));
}  

function* saga() {
    yield takeEvery(PLAYERS__GET_PLAYERS, onGetPlayers);
}

export default saga;