import { SnackbarContent, SnackbarProvider, useSnackbar } from "notistack";
import { useAppDispatch } from "../../../../hooks/redux";
import { AppNotification } from "../types";
import { closeNotification } from "../actions";
import { Button, OverlayTrigger } from "react-bootstrap";

const NotificationProvider = (props: any) => {
    const { children } = props;
    const dispatch = useAppDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    return (
        <SnackbarProvider
            maxSnack={1}
            autoHideDuration={5000}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            content={(_, obj: any) => {
                const notification = obj as AppNotification;
                return (
                    <SnackbarContent>
                        <Button onClick={() => enqueueSnackbar('I love hooks')}>
      Show snackbar
    </Button>
                    </SnackbarContent>
                )
            }}>
                {children}
            </SnackbarProvider>
    );
};

export default NotificationProvider;