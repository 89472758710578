import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../api/api";
import { GetCoachesResponse } from "../../../api/responses";
import { FetchResult } from "../../../api/types";
import { getCoachesFailed, getCoachesStarted, getCoachesSuccess } from "./actions";
import { COACHES__GET_COACHES } from "./constants";

import { Coach } from "./types";

function* onGetCoaches() {
    
    yield put(getCoachesStarted());

    let response : FetchResult<GetCoachesResponse> = {}

    response = yield call(api.coachesList);

    const data = response.data ?? [];

    if (response.error) {
        yield put(getCoachesFailed(response.error));
    }

    yield put(getCoachesSuccess(data as Coach[]));
}  

function* saga() {
    yield takeEvery(COACHES__GET_COACHES, onGetCoaches);
}

export default saga;