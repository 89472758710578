import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../api/api";
import { GetLeaguesResponse } from "../../../api/responses";
import { FetchResult } from "../../../api/types";

import { getLeaguesFailed, getLeaguesStarted, getLeaguesSuccess } from "./actions";
import { LEAGUES__GET_LEAGUES } from "./constants";
import { League } from "./types";

function* onGetLeagues() {
    
    yield put(getLeaguesStarted());

    let response : FetchResult<GetLeaguesResponse> = {}

    response = yield call(api.leaguesList);

    const data = response.data ?? [];

    if (response.error) {
        yield put(getLeaguesFailed(response.error));
    }

    yield put(getLeaguesSuccess(data as League[]));
}  

function* saga() {
    yield takeEvery(LEAGUES__GET_LEAGUES, onGetLeagues);
}

export default saga;