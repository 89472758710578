import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import root_reducer from "./root-reducer";
import saga from "./root-saga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: root_reducer,
    middleware: [sagaMiddleware]
});

sagaMiddleware.run(saga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;