export function getCurrentSeasonYear(): number {

    var year = 0;

    var currentDate = new Date();

    if (currentDate.getMonth() >= 5) {
        year = currentDate.getFullYear();
    } else {
        year = currentDate.getFullYear() - 1;
    }
    
    return year;
}