import produce from "immer";
import { RequestStatus } from "../../../api/types";

import { GetLeaguesSuccessAction } from "./actions";

import { LEAGUES__GET_LEAGUES_FAILED, LEAGUES__GET_LEAGUES_STARTED, LEAGUES__GET_LEAGUES_SUCCESS } from "./constants";
import { League } from "./types";

interface StateType {
    data: League[],
    status: RequestStatus
}

const initialState: StateType = {
    data: [],
    status: RequestStatus.Idle
}

const reducer = produce((state, action) => {
    switch(action.type) {
        case LEAGUES__GET_LEAGUES_STARTED:
            state.status = RequestStatus.InProgress;
            break;
        case LEAGUES__GET_LEAGUES_SUCCESS:
            state.status = RequestStatus.Success;
            state.data = (action as GetLeaguesSuccessAction).data;
            break;
        case LEAGUES__GET_LEAGUES_FAILED:
            state.status = RequestStatus.Failed;
            break;
        default:
            return state;
    }
}, initialState);

export default reducer;