import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { NavLink } from "react-router-dom";
import { RequestStatus } from "../../../api/types";
import { getCurrentSeasonYear } from "../../../functions/getCurrentSeasonYear";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import CountryFlag from "../../../modules/CountryFlag/CountryFlag";
import LoadingComponent from "../../../modules/LoadingComponent/LoadingComponent";

import { getLeagues } from "./actions";

const Leagues = () => {
  const dispatch = useAppDispatch();
    const leagues = useAppSelector(state => state.leagues.data);
    const status = useAppSelector(state => state.leagues.status)

  useEffect(() => {
    dispatch(getLeagues());
  }, [dispatch]);

  const year = getCurrentSeasonYear();

    if (status === RequestStatus.InProgress) return <LoadingComponent />;
  else return (
    <div className="leagues">
      <Row>
        {leagues.map((league) => (
          <Col xs={12} md={6} lg={3} className="league-row">
            <NavLink to={`/leagues/${year}/${league.id}`}>
              <Card border="secondary" className="league">
                <Card.Header>
                  {league.country} - {league.name}
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <CountryFlag countryCode={league.countryCode} />
                  </Card.Text>
                </Card.Body>
              </Card>
            </NavLink>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Leagues;
