import { Col, Container, Row, Spinner } from "react-bootstrap";

export default function LoadingComponent() {
    return (
        <Container>
            <Row>
                <Col xs={1}>
                    <Spinner animation="border" variant="dark" />
                </Col>
                <Col>
                    Trwa ładowanie...
                </Col>
            </Row>
        </Container>
    );
}