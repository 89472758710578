import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../api/api";
import { GetLeagueDetailsResponse } from "../../../api/responses";
import { FetchResult } from "../../../api/types";
import { GetLeagueDetailsAction, getLeagueDetailsFailed, getLeagueDetailsSuccess } from "./actions";
import { LEAGUES__GET_LEAGUE_DETAILS } from "./constants";

import { League } from "./types";

function* onGetLeagueDetails(action: GetLeagueDetailsAction) {
    const { data, error }: FetchResult<GetLeagueDetailsResponse> = yield call(api.leagueDetails, action.season, action.id);

    const League = { ...data } as League;

    yield error ? put(getLeagueDetailsFailed(error)) : put(getLeagueDetailsSuccess(League!));
}  

function* saga() {
    yield takeEvery(LEAGUES__GET_LEAGUE_DETAILS, onGetLeagueDetails);
}

export default saga;