export const NOTIFY = 'NOTIFY';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const PRUNE_NOTIFICATIONS = 'PRUNE_NOTIFICATIONS';

export enum NotificationType {
    Success = "success",
    Warning = "warning",
    Information = "info",
    Error = "error"
}