import { uniqueId } from "lodash";
import { IAction, VoidAction } from "../../types";
import { CLOSE_NOTIFICATION, NOTIFY, NotificationType, PRUNE_NOTIFICATIONS, REMOVE_NOTIFICATION } from "./constants";
import { AppNotification } from "./types";

export interface CloseNotificationAction extends IAction {
    notificationId: string
}

export interface RemoveNotificationAction extends IAction {
    notificationId: string
}

export interface NotifyAction extends IAction {
    notification: AppNotification
}

export const notify = (type: NotificationType, message: string): NotifyAction => ({
    type: NOTIFY,
    notification: {
        id: uniqueId("notification_"),
        type: type,
        autohide: true,
        dismissed: false,
        message
    }
});

export const closeNotification = (notificationId: string): CloseNotificationAction => ({
    type: CLOSE_NOTIFICATION, notificationId
});

export const removeNotification = (notificationId: string): RemoveNotificationAction => ({
    type: REMOVE_NOTIFICATION, notificationId
});

export const pruneNotifications = (): VoidAction => ({
    type: PRUNE_NOTIFICATIONS
});