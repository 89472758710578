import produce from "immer";
import { RequestStatus } from "../../../api/types";
import { GetClubDetailsActionSuccess } from "./actions";
import { CLUBS__GET_CLUB_DETAILS_FAILED, CLUBS__GET_CLUB_DETAILS_STARTED, CLUBS__GET_CLUB_DETAILS_SUCCESS } from "./constants";

import { Club } from "./types";

interface StateType {
    data: Club | null,
    status: RequestStatus
}

const initialState: StateType = {
    data: null,
    status: RequestStatus.Idle
}

const reducer = produce((state, action) => {
    switch(action.type) {
        case CLUBS__GET_CLUB_DETAILS_STARTED:
            state.status = RequestStatus.InProgress;
            state.data = null;
            break;
        case CLUBS__GET_CLUB_DETAILS_SUCCESS:
            state.status = RequestStatus.Success;
            state.data = (action as GetClubDetailsActionSuccess).data;
            break;
        case CLUBS__GET_CLUB_DETAILS_FAILED:
            state.status = RequestStatus.Failed;
            break;
        default:
            return state;
    }
}, initialState);

export default reducer;