import produce from "immer";
import { RequestStatus } from "../../api/types";
import { SendMessageRequest } from "./types";
import { CONTACT_FORM__SEND_MESSAGE_EVENT_FAILED, CONTACT_FORM__SEND_MESSAGE_EVENT_STARTED, CONTACT_FORM__SEND_MESSAGE_EVENT_SUCCESS } from "./constants";

interface StateType {
    data: SendMessageRequest | null,
    status: RequestStatus
}

const initialState: StateType = {
    data: null,
    status: RequestStatus.Idle
}

const reducer = produce((state, action) => {
    switch (action.type) {
        case CONTACT_FORM__SEND_MESSAGE_EVENT_STARTED:
            state.status = RequestStatus.InProgress;
            state.data = null;
            break;
        case CONTACT_FORM__SEND_MESSAGE_EVENT_SUCCESS:
            state.status = RequestStatus.Success;
            break;
        case CONTACT_FORM__SEND_MESSAGE_EVENT_FAILED:
            state.status = RequestStatus.Failed;
            break;
        default:
            return state;
    }
}, initialState);

export default reducer;