import produce from "immer";
import App from "./App";
import { APP_LOAD, APP_READY, APP_START } from "./constants";
import { AppStatus } from "./types";

interface StateType {
    status: AppStatus
};

const initialState: StateType = {
    status: AppStatus.None
}

const reducer = produce((state, action) => {
    switch (action.type) {
        case APP_START:
            state.status = AppStatus.Started;
            break;
        
        case APP_LOAD:
            state.status = AppStatus.Loading;
            break;

        case APP_READY:
            state.status = AppStatus.Ready;
            break;
        
        default:
            return state;
    }
}, initialState);

export default reducer;