import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../api/api";
import { GetClubDetailsResponse, GetClubsResponse } from "../../../api/responses";
import { FetchResult } from "../../../api/types";
import { GetClubDetailsAction, getClubDetailsFailed, getClubDetailsStarted, getClubDetailsSuccess } from "./actions";
import { CLUBS__GET_CLUB_DETAILS } from "./constants";

import { Club } from "./types";

function* onGetClubDetails(action: GetClubDetailsAction) {

    const { data, error }: FetchResult<GetClubDetailsResponse> = yield call(api.clubDetails, action.season, action.id);

    const club = { ...data } as Club;

    yield error ? put(getClubDetailsFailed(error)) : put(getClubDetailsSuccess(club!));
    
   
}  

function* saga() {
    yield takeEvery(CLUBS__GET_CLUB_DETAILS, onGetClubDetails);
}

export default saga;