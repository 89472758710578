import { combineReducers } from "@reduxjs/toolkit";
import { routerReducer as router } from "react-router-redux";
import app from "./reducer";
import routing from "./components/Routing/reducer";
import club from "../features/clubs/details/reducer";
import clubs from "../features/clubs/list/reducer";
import coach from "../features/coaches/details/reducer";
import coaches from "../features/coaches/list/reducer";
import contactForm from "../modules/ContactForm/reducer";
import league from "../features/leagues/details/reducer";
import leagues from "../features/leagues/list/reducer";
import player from "../features/players/details/reducer";
import players from "../features/players/list/reducer";
import notifier from "./components/Notifier/reducer";

const reducer = combineReducers({
    app,
    routing,
    club,
    clubs,
    coach,
    coaches,
    contactForm,
    league,
    leagues,
    notifier,
    player,
    players
});

const root_reducer = (state: any, action: any) => {

    return reducer(state, action);
}

export default root_reducer;