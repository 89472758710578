import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { RequestStatus } from "../../../api/types";
import { genericSearch } from "../../../functions/genericSearch";
import { getCurrentSeasonYear } from "../../../functions/getCurrentSeasonYear";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import CardElement from "../../../modules/CardElement/CardElement";
import LoadingComponent from "../../../modules/LoadingComponent/LoadingComponent";
import SearchInput from "../../../modules/SearchInput/SearchInput";
import { getClubs } from "./actions";
import { Club } from "./types";


const Clubs = () => {
    const dispatch = useAppDispatch();
    const clubs = useAppSelector(state => state.clubs.data);
    const status = useAppSelector(state => state.clubs.status);

    const [query, setQuery] = useState<string>("");

    useEffect(() => {
      dispatch(getClubs())
    }, [dispatch]);

    const year = getCurrentSeasonYear();

    const filterClubs = clubs
        .filter((club) =>
            genericSearch<Club>(club, ["name", "country"], query)
    );

    if (status === RequestStatus.InProgress) return <LoadingComponent />;
    else return (
      <>
        <SearchInput onChangeSearchQuery={(query) => setQuery(query)} placeholder="Wyszukaj klub po nazwie lub kraju..." />
          <Row>
          {filterClubs.map(club =>
            <CardElement countryCode={club.countryCode} link={`/clubs/${year}/${club.id}`} name={club.name} />
          )}
          </Row> 
      </>
      
    )
}

export default Clubs;