import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { useEffect } from "react";
import { forEach } from "lodash";
import { closeNotification } from "../actions";

let displayed: string[] = [];

const Notifier = () => {
    const notifications = useAppSelector(state => state.notifier.notifications);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const setDisplayed = (id: string) => {
        displayed.push(id);
    };

    const removeDisplayed = (id: string) => {
        displayed = [...displayed.filter(key => id != key)];
    };

    useEffect(() => {
        forEach(notifications, notification => {
            if (notification.dismissed) {
                closeSnackbar(notification.id);
                return;
            }
            if (displayed.includes(notification.id))
                return;
            
                enqueueSnackbar(notification as any, {
                    key: notification.id,
                    persist: !notification.autohide,
                    onExited: (_, key) => {
                        dispatch(closeNotification(notification.id));
                        removeDisplayed(notification.id);
                    }
                });

                setDisplayed(notification.id);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;