import { put, takeLatest } from "redux-saga/effects";
import { filter } from "lodash";
import { setDefaultRoute, setRoutes } from "./actions";
import routes from "./route-config";
import { APP_START } from "../../constants";

function* onStart() {
    const applicable = filter(routes, route => route.path !== "")

    yield put(setDefaultRoute(routes.Home));
    yield put(setRoutes(applicable));
}

function* saga() {
    yield takeLatest(APP_START, onStart);
}

export default saga;