import { all, fork } from "redux-saga/effects";
import app from "./saga";
import routing from "./components/Routing/saga";
import club from "../features/clubs/details/saga";
import clubs from "../features/clubs/list/saga";
import coach from "../features/coaches/details/saga";
import coaches from "../features/coaches/list/saga";
import contactForm from "../modules/ContactForm/saga";
import league from "../features/leagues/details/saga";
import leagues from "../features/leagues/list/saga";
import player from "../features/players/details/saga";
import players from "../features/players/list/saga";



function* saga() {
    yield all([
        fork(app),
        fork(routing),
        fork(club),
        fork(clubs),
        fork(coach),
        fork(coaches),
        fork(contactForm),
        fork(league),
        fork(leagues),
        fork(player),
        fork(players)
    ])
}

export default saga;