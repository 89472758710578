import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../api/api";
import { GetPlayerDetailsResponse } from "../../../api/responses";
import { FetchResult } from "../../../api/types";
import { GetPlayerDetailsAction, getPlayerDetailsFailed, getPlayerDetailsSuccess } from "./actions";
import { PLAYERS__GET_PLAYER_DETAILS } from "./constants";

import { Player } from "./types";

function* onGetPlayerDetails(action: GetPlayerDetailsAction) {
    const { data, error }: FetchResult<GetPlayerDetailsResponse> = yield call(api.playerDetails, action.id);

    const Player = { ...data } as Player;

    yield error ? put(getPlayerDetailsFailed(error)) : put(getPlayerDetailsSuccess(Player!));
}  

function* saga() {
    yield takeEvery(PLAYERS__GET_PLAYER_DETAILS, onGetPlayerDetails);
}

export default saga;