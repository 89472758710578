export enum RequestStatus {
    Idle = 0,
    InProgress,
    Success,
    Failed
}

export interface FetchResult<T> {
    data?: T;
    error?: string;
}