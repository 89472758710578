import { call, put, takeLatest } from "redux-saga/effects";
import { SendMessageEventAction, sendMessageEventFailed, sendMessageEventStarted, sendMessageEventSuccess } from "./actions";
import { FetchResult } from "../../api/types";
import api from "../../api/api";
import { CONTACT_FORM__SEND_MESSAGE_EVENT, CONTACT_FORM__SEND_MESSAGE_EVENT_SUCCESS } from "./constants";
import { notify } from "../../app/components/Notifier/actions";
import { NotificationType } from "../../app/components/Notifier/constants";

function* onSendMessageEvent(action: SendMessageEventAction) {
    yield put(sendMessageEventStarted());
    
    const { error }: FetchResult<null> = yield call(api.sendMessage, action.values);

    yield error ? put(sendMessageEventFailed(error)) : put(sendMessageEventSuccess());
}

function* onSendMessageEventSuccess() {
    yield put(notify(NotificationType.Success, "Wiadomość została wysłana!"));
}

function* saga() {
    yield takeLatest(CONTACT_FORM__SEND_MESSAGE_EVENT, onSendMessageEvent);
    yield takeLatest(CONTACT_FORM__SEND_MESSAGE_EVENT_SUCCESS, onSendMessageEventSuccess);
}

export default saga;