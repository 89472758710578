import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { returnCoachRole } from "../../../../functions/returnCoachRole";
import CountryFlag from "../../../../modules/CountryFlag/CountryFlag";
import { CoachesListProps } from "./types";


const CoachesList = (props: CoachesListProps) => {
    const { coaches } = props;

    const navigate = useNavigate();
    const coachRowClick = (id: string) => {
        navigate(`/coaches/${id}`);
      } 

    return (
      <Table striped bordered hover>
      <thead className="align-items-center">
        <tr>
        <th>Trener</th>
          <th>Rola</th>
          <th>Rok urodzenia</th>
        </tr>
      </thead>
      <tbody>
      {coaches.map(coach =>
                  <tr onClick={()=> coachRowClick(coach.id)} className={"list-element " && coach.didHeChangeClubDuringSeason ?  "unactive-player" : ""}>
                  <td>
                    <div className='d-flex align-items-center'>
                        <CountryFlag countryCode={coach.countryCode} />
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>{coach.name} {coach.surname}</p>
                        <p className='text-muted mb-0'>{coach.country}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className='text-muted mb-0'>{returnCoachRole(coach.role)}</p>
                  </td>
                  <td>
                    <p className='text-muted mb-0'>{coach.birthdayYear === 1900 ? "-" : coach.birthdayYear}</p>
                  </td>
                </tr>
                    )}
              </tbody>
    </Table>
    );
}

export default CoachesList;