
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { select, SelectEffect } from "redux-saga/effects";
import { AppDispatch, RootState } from '../app/store';


export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export function selectState<T>(selector: (s: RootState) => T): SelectEffect {
    return select(selector);
}