import produce from "immer";
import { SetDefaultRouteAction, SetRedirectAction, SetRoutesAction } from "./actions";
import { ROUTING__SET_DEFAULT_ROUTE, ROUTING__SET_REDIRECT, ROUTING__SET_ROUTES } from "./constants";
import routes from "./route-config";
import { Route, RouteType } from "./types";

interface StateType {
    routes: Route[],
    redirect: string | null,
    defaultRoute: Route
}

const initialState: StateType = {
    routes: [],
    redirect: null,
    defaultRoute: routes[RouteType.Home]
}

const reducer = produce((state, action) => {
    switch (action.type) {
        case ROUTING__SET_ROUTES:
            state.routes = (action as SetRoutesAction).routes;
            break;
        case ROUTING__SET_REDIRECT:
            state.redirect = (action as SetRedirectAction).redirect;
            break;
        case ROUTING__SET_DEFAULT_ROUTE:
            state.defaultRoute = (action as SetDefaultRouteAction).defaultRoute;
            break;
        default:
            return state;
    }
}, initialState);

export default reducer;