import ClubDetails from "../../../features/clubs/details/ClubDetails";
import Clubs from "../../../features/clubs/list/Clubs";
import CoachDetails from "../../../features/coaches/details/CoachDetails";
import Coaches from "../../../features/coaches/list/Coaches";
import Contact from "../../../features/contact/Contact";
import Home from "../../../features/home/Home";
import LeagueDetails from "../../../features/leagues/details/LeagueDetails";
import Leagues from "../../../features/leagues/list/Leagues";
import PlayerDetails from "../../../features/players/details/PlayerDetails";
import Players from "../../../features/players/list/Players";

import { Route, RouteType } from "./types";


export const routes: { [key in RouteType]: Route } = {
    [RouteType.Home]: {
        type: RouteType.Home,
        path: '/',
        Page: Home
    },
    [RouteType.Club]: {
        type: RouteType.Club,
        path: '/clubs/:season/:id',
        Page: ClubDetails
    },
    [RouteType.Clubs]: {
        type: RouteType.Clubs,
        path: '/clubs',
        Page: Clubs
    },
    [RouteType.Coach]: {
        type: RouteType.Coach,
        path: '/coaches/:id',
        Page: CoachDetails
    },
    [RouteType.Coaches]: {
        type: RouteType.Coaches,
        path: '/coaches',
        Page: Coaches
    },
    [RouteType.League]: {
        type: RouteType.League,
        path: '/leagues/:season/:id',
        Page: LeagueDetails
    },
    [RouteType.Leagues]: {
        type: RouteType.Leagues,
        path: '/leagues',
        Page: Leagues
    },
    [RouteType.Player]: {
        type: RouteType.Player,
        path: '/players/:id',
        Page: PlayerDetails
    },
    [RouteType.Players]: {
        type: RouteType.Players,
        path: '/players',
        Page: Players
    },
    [RouteType.Contact]: {
        type: RouteType.Contact,
        path: '/contact',
        Page: Contact
    }
};

export default routes;