export function returnCoachRole(role: number): string {

    switch(role) {
        case 1: return "Szkoleniowiec";
            break;
        case 2: return "Asystent szkoleniowca";
            break;
        case 3: return "Statystyk";
            break;
        case 4: return "Trener przygotowania fizycznego";
            break;
        case 5: return "Fizjoterapeuta";
            break;
        case 6: return "Lekarz";
            break;
        case 7: return "Menager drużyny";
            break;
        default:
            return "Szkoleniowiec";
    }
}