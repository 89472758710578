import { ErrorAction, IAction, VoidAction } from "../../../app/types";
import { Coach } from "../list/types";
import { COACHES__GET_COACH_DETAILS, COACHES__GET_COACH_DETAILS_FAILED, COACHES__GET_COACH_DETAILS_STARTED, COACHES__GET_COACH_DETAILS_SUCCESS } from "./constants";


export interface GetCoachDetailsAction extends IAction {
    id: string
};

export interface GetCoachDetailsActionSuccess extends IAction {
    data: Coach;
};

export const getCoachDetails = (id: string): GetCoachDetailsAction => ({
    type: COACHES__GET_COACH_DETAILS,
    id
});

export const getCoachDetailsStarted = (): VoidAction => ({
    type: COACHES__GET_COACH_DETAILS_STARTED
});

export const getCoachDetailsFailed = (error: string): ErrorAction => ({
    type: COACHES__GET_COACH_DETAILS_FAILED,
    error
});

export const getCoachDetailsSuccess = (data: Coach): GetCoachDetailsActionSuccess => ({
    type: COACHES__GET_COACH_DETAILS_SUCCESS,
    data
});