import produce from "immer";
import { RequestStatus } from "../../../api/types";
import { GetPlayersSuccessAction } from "./actions";
import { PLAYERS__GET_PLAYERS_FAILED, PLAYERS__GET_PLAYERS_STARTED, PLAYERS__GET_PLAYERS_SUCCESS } from "./constants";

import { Player } from "./types";

interface StateType {
    data: Player[],
    status: RequestStatus
}

const initialState: StateType = {
    data: [],
    status: RequestStatus.Idle
}

const reducer = produce((state, action) => {
    switch(action.type) {
        case PLAYERS__GET_PLAYERS_STARTED:
            state.status = RequestStatus.InProgress;
            break;
        case PLAYERS__GET_PLAYERS_SUCCESS:
            state.status = RequestStatus.Success;
            state.data = (action as GetPlayersSuccessAction).data;
            break;
        case PLAYERS__GET_PLAYERS_FAILED:
            state.status = RequestStatus.Failed;
            break;
        default:
            return state;
    }
}, initialState);

export default reducer;