import produce from "immer";
import { AppNotification } from "./types";
import { CLOSE_NOTIFICATION, NOTIFY, PRUNE_NOTIFICATIONS, REMOVE_NOTIFICATION } from "./constants";
import { CloseNotificationAction, NotifyAction, RemoveNotificationAction } from "./actions";

interface StateType {
    notifications: {[id: string]: AppNotification}
}

const initialState: StateType = {
    notifications: {}
};

const reducer = produce((state, action) => {
    switch (action.type) {
        case NOTIFY:
            const notifyAction = action as NotifyAction;
            state.notifications[notifyAction.notification.id] = notifyAction.notification;
            break;
        case CLOSE_NOTIFICATION:
            const closeNotificationAction = action as CloseNotificationAction;
            if (state.notifications.hasOwnProperty(closeNotificationAction.notificationId))
                state.notifications[closeNotificationAction.notificationId].dismissed = true;
            break;
        case REMOVE_NOTIFICATION:
            const removeNotificationAction = action as RemoveNotificationAction;
            delete state.notifications[removeNotificationAction.notificationId];
            break;
        case PRUNE_NOTIFICATIONS:
            state.notifications = initialState.notifications;
            break;
        default:
            return state;
    }
}, initialState);

export default reducer;