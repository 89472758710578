import { useEffect } from "react";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { RequestStatus } from "../../../api/types";
import { getCurrentSeasonYear } from "../../../functions/getCurrentSeasonYear";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import CardElement from "../../../modules/CardElement/CardElement";
import LoadingComponent from "../../../modules/LoadingComponent/LoadingComponent";
import { getLeagueDetails } from "./actions";

const LeagueDetails = () => {
    const { season, id } = useParams<{ season: string, id: string }>();

    const dispatch = useAppDispatch();
    const league = useAppSelector(state => state.league.data);
    const status = useAppSelector(state => state.league.status);

    useEffect(() => {
        dispatch(getLeagueDetails(season!, id!))
    }, [dispatch, season, id]);

    const nextYearSeason = parseInt(season!) + 1;

    if (status === RequestStatus.InProgress) return <LoadingComponent />;
    else return (
        <>
            <Row>
                <Col xs={10}>
                    <div className="league-details-header">
                        Sezon {season}/{nextYearSeason}
                    </div>
                </Col>
                <Col xs={2} className="league-dropdown">
                    <DropdownButton id="dropdown-basic-button" variant="secondary" title="Wybierz sezon">
                        {league?.seasons.map(leagueseason =>
                            <Dropdown.Item href={`/leagues/${leagueseason.split('/')[0]}/${id}`}>{leagueseason}</Dropdown.Item>
                        )}
                    </DropdownButton>
                </Col>
            </Row>
            <Row>
                {league?.clubsInLeagues.map(club =>
                    <CardElement countryCode={club.countryCode} link={`/clubs/${season}/${club.id}`} name={club.name} />
                )}
                {league?.name === null &&
                    <div className="no-clubs">
                        Nie posiadamy jeszcze danych o wybranym sezonie. Prosimy użyć sezonu z listy rozwijalnej u góry strony.
                    </div>
                }
            </Row>
            {league?.map &&
                <iframe src={league.map} width="100%" height="500px"></iframe>
            }
        </>
    )
}

export default LeagueDetails;