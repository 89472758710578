import axios, { AxiosRequestConfig } from "axios";
import { FetchResult } from "./types";
import { baseURL } from './config';
var qs = require('qs');

class client {
    private static _errorMessage = "Wystąpił błąd podczas komunikacji z serwerem!";
    private static execute = async <T>(url: string, method: "get" | "post" | "patch" | "delete", params?: any): Promise<FetchResult<T>> => {
        try {
            const config: AxiosRequestConfig = {
                url,
                baseURL,
                method,
                params: method === "get" ? params : null,
                data: method !== "get" ? params : null,
                //paramsSerializer: method === "get" ? (val: any) => qs.stringify(val, {skipNulls: true}) : undefined
            };

            const { data } = await axios.request<T>(config);
            return { data };
        }
        catch (error) {
            console.log('error', error)
            if (axios.isAxiosError(error) && error.response?.data?.message) {
                console.log('axios error', error.response)
                return { error: error.response?.data?.message}
            }

            return { error: client._errorMessage }
        }
    }

    public static get = async<T>(url: string, params?: any): Promise<FetchResult<T>> => {
        return client.execute(url, "get", params);
    }

    public static patch = async<T>(url: string, params?: any): Promise<FetchResult<T>> => {
        return client.execute(url, "patch", params);
    }
    public static post = async<T>(url: string, params?: any): Promise<FetchResult<T>> => {
        return client.execute(url, "post", params);
    }
    public static delete = async<T>(url: string, params?: any): Promise<FetchResult<T>> => {
        return client.execute(url, "delete", params);
    }

}

export default client;