import { IAction } from "../../types";
import { ROUTING__SET_DEFAULT_ROUTE, ROUTING__SET_REDIRECT, ROUTING__SET_ROUTES } from "./constants";
import { Route } from "./types";


export interface SetRoutesAction extends IAction {
    routes: Route[];
}

export interface SetRedirectAction extends IAction {
    redirect: string | null;
}

export interface SetDefaultRouteAction extends IAction {
    defaultRoute: Route;
}

export const setRoutes = (routes: Route[]): SetRoutesAction => ({
    type: ROUTING__SET_ROUTES,
    routes
});

export const setDefaultRoute = (defaultRoute: Route): SetDefaultRouteAction => ({
    type: ROUTING__SET_DEFAULT_ROUTE,
    defaultRoute
});

export const setRedirect = (redirect: string | null): SetRedirectAction => ({
    type: ROUTING__SET_REDIRECT,
    redirect
})