import React, { useLayoutEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppDispatch } from '../hooks/redux';
import { startApp } from './actions';
import PageHeader from './components/PageHeader/PageHeader';
import Routes from './components/Routing/Routes';
import Notifier from './components/Notifier/components/Notifier';
import NotificationProvider from './components/Notifier/components/NotificationProvider';

const App = () => {

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(startApp())
  }, [dispatch]);


  return (
    
    <NotificationProvider>
<BrowserRouter>
<Notifier />
      <PageHeader />
      <Routes />
</BrowserRouter>
    </NotificationProvider>
      
  );
}

export default App;
