import { IconProps } from "./types";

const SearchIcon = (props: IconProps) => {

    const { className } = props;

    return (
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-2 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
        </div>
    );
}

export default SearchIcon;