import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { RequestStatus } from "../../../api/types";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import CountryFlag from "../../../modules/CountryFlag/CountryFlag";
import LoadingComponent from "../../../modules/LoadingComponent/LoadingComponent";
import { getCoachDetails } from "./actions";

const CoachDetails = () => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useAppDispatch();
    const coach = useAppSelector(state => state.coach.data);
    const status = useAppSelector(state => state.coach.status);

    useEffect(() => {
      dispatch(getCoachDetails(id!))
    }, [dispatch, id]);

    if (status === RequestStatus.InProgress) return <LoadingComponent />;
    else return (
      coach &&
      <div className="coach">
        <div className="coach-title">
          <div className="coach-flag">
            <CountryFlag countryCode={coach.countryCode} />
          </div>
          <div className="coach-name">
            {coach.name} {coach.surname}
          </div>
        </div>
        <div className="coach-details">
          <div className="coach-table">
        <Table striped bordered hover>
      <tbody>
                  <tr className="coach-row">
          <td>
            <p className='fw-normal mb-1'>Kraj pochodzenia</p>
          </td>
          <td>
            <p className='text-muted mb-0'>{coach.country} </p>
          </td>
        </tr>
        {coach.birthday !== "01/01/1900" &&
        <tr className="coach-row">
          <td>
            <p className='fw-normal mb-1'>Data urodzenia</p>
          </td>
          <td>
            <p className='text-muted mb-0'>{coach.birthday}</p>
          </td>
        </tr>
        }
        {coach.playerId !== "00000000-0000-0000-0000-000000000000" &&
        <tr className="coach-row">
        <td>
          <p className='fw-normal mb-1'>Kariera zawodnicza</p>
        </td>
        <td>
          <p className='text-muted mb-0'><NavLink to={`/players/${coach.playerId}`} className="club-link">Przejdź do kariery zawodniczej</NavLink></p>
        </td>
      </tr>
        }
      </tbody>
    </Table>
    </div>
          <div className="coach-career">
          <p className="career">Przebieg kariery: </p>
          {coach.clubsInCareer && (
                            coach.clubsInCareer.map((club) => (
                                <div>
                                    <NavLink to={`/clubs/${club.seasons.split('/')[0]}/${club.clubId}`} className="club-link">
                                        <div>
                                            <div className="club-details">
                                              <div className="club-flag">
                                              <CountryFlag countryCode={club.clubCountryCode} />
                                              </div>
                                              <div className="club-name">
                                              {club.clubName} ({club.seasons})
                                              </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            )))
                        }
          </div>
      </div>
      </div>
    )
}

export default CoachDetails;