import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../api/api";
import { GetClubsResponse } from "../../../api/responses";
import { FetchResult } from "../../../api/types";
import { getClubsFailed, getClubsStarted, getClubsSuccess } from "./actions";
import { CLUBS__GET_CLUBS } from "./constants";

import { Club } from "./types";

function* onGetClubs() {
    
    yield put(getClubsStarted());

    let response : FetchResult<GetClubsResponse> = {}

    response = yield call(api.clubsList);

    const data = response.data ?? [];

    if (response.error) {
        yield put(getClubsFailed(response.error));
    }

    yield put(getClubsSuccess(data as Club[]));
}  

function* saga() {
    yield takeEvery(CLUBS__GET_CLUBS, onGetClubs);
}

export default saga;