import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { RequestStatus } from "../../../api/types";
import { returnSeasonFullString } from "../../../functions/returnSeasonFullString";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import LoadingComponent from "../../../modules/LoadingComponent/LoadingComponent";
import { getClubDetails } from "./actions";
import CoachesList from "./coachesList/CoachesList";
import PlayersList from "./playersList/PlayersList";

const ClubDetails = () => {
    const { season, id } = useParams<{ season: string, id: string }>();

    const dispatch = useAppDispatch();
    const club = useAppSelector(state => state.club.data);
    const status = useAppSelector(state => state.club.status);

    useEffect(() => {
      dispatch(getClubDetails(season!, id!))
    }, [dispatch, season, id]);

    if (status === RequestStatus.InProgress) return <LoadingComponent />;
    else return (
      club &&
        <>
          <div className="clubTitle">{club.name} - {returnSeasonFullString(season)}</div>
          <PlayersList players={club.playersInSeasons} />
          <CoachesList coaches={club.coachesInSeasons} />
        </>
      
    )
}

export default ClubDetails;