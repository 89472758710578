import { Action } from 'redux';

export enum AppStatus {
    None = 0,
    Started = 1,
    Loading = 2,
    Ready = 3
};

export interface IAction extends Action<string> {
    type: string;
};

export interface ErrorAction extends IAction {
    error: string;
};

export interface VoidAction extends IAction { };