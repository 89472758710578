import React from "react";
import { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import SearchIcon from "../../icons/SearchIcon";
import { ISearchProps } from "./types";

export default function SearchInput(props: ISearchProps) {
    const [searchQuery, setSearchQuery] = useState<string | undefined>();
    const { onChangeSearchQuery, placeholder } = props;
    const debouncedSearchQuery = useDebounce(searchQuery, 250);

    useEffect(() => {
        if (debouncedSearchQuery !== undefined) {
            onChangeSearchQuery(debouncedSearchQuery);
        }
    }, [debouncedSearchQuery, onChangeSearchQuery]);

    return (
        <div className="search-panel">
            <SearchIcon className="search-icon" />
            <input type="text" name="search"        
                placeholder={placeholder}
                className="search-input" 
                autoComplete="false"
                onChange={(event) => setSearchQuery(event.target.value)} 
                />
        </div>
    );
}