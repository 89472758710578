import { useCallback, useMemo, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { sendMessageEvent } from "./actions";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SendMessageRequest } from "./types";
import { Formik, FormikErrors } from "formik";
import eventContentValidator from "../../features/validators/eventContentValidator";
import { some } from "lodash";
import { RequestStatus } from "../../api/types";
import { error } from "console";


const ContactForm = () => {

    const dispatch = useAppDispatch();
    const [form, setForm] = useState<SendMessageRequest | null>(null);
    const status = useAppSelector(state => state.contactForm.status);

    const initialValues = useMemo<SendMessageRequest>(() => ({
        firstName: '',
        lastName: '',
        email: '',
        content: ''
    }), []);

    const validator = useCallback((values: SendMessageRequest) => {
        const errors: FormikErrors<SendMessageRequest> = {
            content: eventContentValidator(values.content)
        }
        return some(errors, Boolean) ? errors : {};
    }, []);

      const sendMessage = (values: SendMessageRequest) => {
        console.log(values);
        dispatch(sendMessageEvent(values));
      }

        return (
            <>
                <Formik<SendMessageRequest>
                    initialValues={initialValues}
                    validate={validator}
                    onSubmit={(values) => sendMessage(values)}
                    >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group className="col col-sm-4">
                                <Form.Label>Imię</Form.Label>
                                <Form.Control name="firstName" value={values.firstName} onChange={handleChange} onBlur={handleBlur} className="form-control" />
                            </Form.Group>
                            <Form.Group className="col col-sm-4">
                                <Form.Label>Nazwisko</Form.Label>
                                <Form.Control name="lastName" value={values.lastName} onChange={handleChange} onBlur={handleBlur}className="form-control" />
                            </Form.Group>
                            <Form.Group className="col col-sm-4">
                                <Form.Label>E-Mail</Form.Label>
                                <Form.Control type="email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group>
                                <Form.Label>Treść wiadomości</Form.Label>
                                <Form.Control as="textarea" rows={5} className="form-control" name="content" value={values.content} onChange={handleChange} onBlur={handleBlur} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group className="col col-sm-6">
                                <button type="submit" disabled={status === RequestStatus.InProgress} className="me-4 btn btn-success btn-lg btn-block">Wyślij</button>
                            </Form.Group>
                        </Row>
                    </form>
                )}
                </Formik>
            </>
        );
}

export default ContactForm;