import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { RequestStatus } from "../../../api/types";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import CountryFlag from "../../../modules/CountryFlag/CountryFlag";
import LoadingComponent from "../../../modules/LoadingComponent/LoadingComponent";
import { getPlayerDetails } from "./actions";

const PlayerDetails = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();
    const player = useAppSelector(state => state.player.data);
    const status = useAppSelector(state => state.player.status);

  useEffect(() => {
    dispatch(getPlayerDetails(id!));
  }, [dispatch, id]);

    if (status === RequestStatus.InProgress) return <LoadingComponent />;
    else return (
    player && (
      <div className="player">
        <div className="player-title">
          <div className="player-flag">
            <CountryFlag countryCode={player.countryCode} />
          </div>
          <div className="player-name">
            {player.name} {player.surname}
          </div>
        </div>
        <div className="player-details">
          <div className="player-table">
            <Table striped bordered hover>
              <tbody>
                <tr className="player-row">
                  <td>
                    <p className="fw-normal mb-1">Kraj pochodzenia</p>
                  </td>
                  <td>
                    <p className="text-muted mb-0">{player.country} </p>
                  </td>
                </tr>
                {player.birthday !== "01/01/1900" && (
                  <tr className="player-row">
                    <td>
                      <p className="fw-normal mb-1">Data urodzenia</p>
                    </td>
                    <td>
                      <p className="text-muted mb-0">{player.birthday}</p>
                    </td>
                  </tr>
                )}
                {player.height !== 0 && (
                  <tr className="player-row">
                    <td>
                      <p className="fw-normal mb-1">Wzrost</p>
                    </td>
                    <td>
                      <p className="text-muted mb-0">{player.height}</p>
                    </td>
                  </tr>
                )}
                {player.attackRange !== 0 && (
                  <tr className="player-row">
                    <td>
                      <p className="fw-normal mb-1">Zasięg w ataku</p>
                    </td>
                    <td>
                      <p className="text-muted mb-0">{player.attackRange}</p>
                    </td>
                  </tr>
                )}
                {player.blockRange !== 0 && (
                  <tr className="player-row">
                    <td>
                      <p className="fw-normal mb-1">Zasięg w bloku</p>
                    </td>
                    <td>
                      <p className="text-muted mb-0">{player.blockRange}</p>
                    </td>
                  </tr>
                )}
                {player.weight !== 0 && (
                  <tr className="player-row">
                    <td>
                      <p className="fw-normal mb-1">Waga</p>
                    </td>
                    <td>
                      <p className="text-muted mb-0">{player.weight}</p>
                    </td>
                  </tr>
                )}
                <tr className="player-row">
                  <td>
                    <p className="fw-normal mb-1">Pozycja</p>
                  </td>
                  <td>
                    <p className="text-muted mb-0">{player.position}</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="player-career">
            <p className="career">Przebieg kariery: </p>
            {player.clubsInCareer &&
              player.clubsInCareer.map((club) => (
                <div>
                  <NavLink
                    to={`/clubs/${club.seasons.split("/")[0]}/${club.clubId}`}
                    className="club-link"
                  >
                    <div>
                      <div className="club-details">
                        <div className="club-flag">
                          <CountryFlag countryCode={club.clubCountryCode} />
                        </div>
                        <div className="club-name">
                          {club.clubName} ({club.seasons})
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  );
};

export default PlayerDetails;
