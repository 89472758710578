import { ErrorAction, IAction, VoidAction } from "../../../app/types";
import { LEAGUES__GET_LEAGUES, LEAGUES__GET_LEAGUES_FAILED, LEAGUES__GET_LEAGUES_STARTED, LEAGUES__GET_LEAGUES_SUCCESS } from "./constants";
import { League } from "./types";

export interface GetLeaguesAction extends IAction {};

export interface GetLeaguesSuccessAction extends IAction {
    data: League[];
};

export const getLeagues = (): VoidAction => ({
    type: LEAGUES__GET_LEAGUES
});

export const getLeaguesStarted = (): VoidAction => ({
    type: LEAGUES__GET_LEAGUES_STARTED
});

export const getLeaguesFailed = (error: string): ErrorAction => ({
    type: LEAGUES__GET_LEAGUES_FAILED,
    error
});

export const getLeaguesSuccess = (data: League[]): GetLeaguesSuccessAction => ({
    type: LEAGUES__GET_LEAGUES_SUCCESS,
    data
})