import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

import 'react-awesome-button/dist/themes/theme-blue.css';

const PageHeader = () => {
  
    return (
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="top-menu">
      <Container>
        <Navbar.Brand>
          <NavLink to="/">
            <Button className="button brand">#ŁączyNasSiatka</Button>
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
        <Nav className="me-auto">
        </Nav>
        <Nav className="menu-right">
             <Nav.Link >
               <NavLink to='/leagues'>
               <Button className="button">
                 Ligi
               </Button>
               </NavLink>
             </Nav.Link>
             <Nav.Link>
               <NavLink to='/clubs'>
                 <Button className="button">Kluby</Button>
               </NavLink>
             </Nav.Link>
             <Nav.Link>
               <NavLink to='/players'>
                 <Button className="button">Zawodnicy</Button>
               </NavLink>
             </Nav.Link>
             <Nav.Link>
               <NavLink to='/coaches'>
                 <Button className="button">Trenerzy</Button>
               </NavLink>
             </Nav.Link>
             <Nav.Link>
               <NavLink to='/contact'>
                 <Button className="button">Kontakt</Button>
               </NavLink>
             </Nav.Link>
           </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      // <Navbar bg="light" variant="light" className="top-menu">
      //   <Container>
      //     <Navbar.Brand>
      //       <NavLink to="/">
      //         <Button className="button brand">#ŁączyNasSiatka</Button>
      //       </NavLink>
      //     </Navbar.Brand>
      //     <Nav className="menu-right">
      //       <Nav.Link >
      //         <NavLink to='/leagues'>
      //         <Button className="button">
      //           Ligi
      //         </Button>
      //         </NavLink>
      //       </Nav.Link>
      //       <Nav.Link>
      //         <NavLink to='/clubs'>
      //           <Button className="button">Kluby</Button>
      //         </NavLink>
      //       </Nav.Link>
      //       <Nav.Link>
      //         <NavLink to='/players'>
      //           <Button className="button">Zawodnicy</Button>
      //         </NavLink>
      //       </Nav.Link>
      //       <Nav.Link>
      //         <NavLink to='/coaches'>
      //           <Button className="button">Trenerzy</Button>
      //         </NavLink>
      //       </Nav.Link>
      //       <Nav.Link>
      //         <NavLink to='/contact'>
      //           <Button className="button">Kontakt</Button>
      //         </NavLink>
      //       </Nav.Link>
      //     </Nav>
      //   </Container>
      // </Navbar>
    );
  }
  
  export default PageHeader;
  