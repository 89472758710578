import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../api/api";
import { GetCoachDetailsResponse } from "../../../api/responses";
import { FetchResult } from "../../../api/types";
import { GetCoachDetailsAction, getCoachDetailsFailed, getCoachDetailsSuccess } from "./actions";
import { COACHES__GET_COACH_DETAILS } from "./constants";

import { Coach } from "./types";

function* onGetCoachDetails(action: GetCoachDetailsAction) {
    const { data, error }: FetchResult<GetCoachDetailsResponse> = yield call(api.coachDetails, action.id);

    const Coach = { ...data } as Coach;

    yield error ? put(getCoachDetailsFailed(error)) : put(getCoachDetailsSuccess(Coach!));
}  

function* saga() {
    yield takeEvery(COACHES__GET_COACH_DETAILS, onGetCoachDetails);
}

export default saga;