import { APP_LOAD, APP_READY, APP_START } from './constants';
import { VoidAction } from './types';

export const startApp = (): VoidAction => ({
    type: APP_START
});

export const appLoading = (): VoidAction => ({
    type: APP_LOAD
});

export const appReady = (): VoidAction => ({
    type: APP_READY
});