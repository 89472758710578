import { ErrorAction, IAction, VoidAction } from "../../../app/types";
import { Player } from "../list/types";
import { PLAYERS__GET_PLAYER_DETAILS, PLAYERS__GET_PLAYER_DETAILS_FAILED, PLAYERS__GET_PLAYER_DETAILS_STARTED, PLAYERS__GET_PLAYER_DETAILS_SUCCESS } from "./constants";


export interface GetPlayerDetailsAction extends IAction {
    id: string
};

export interface GetPlayerDetailsActionSuccess extends IAction {
    data: Player;
};

export const getPlayerDetails = (id: string): GetPlayerDetailsAction => ({
    type: PLAYERS__GET_PLAYER_DETAILS,
    id
});

export const getPlayerDetailsStarted = (): VoidAction => ({
    type: PLAYERS__GET_PLAYER_DETAILS_STARTED
});

export const getPlayerDetailsFailed = (error: string): ErrorAction => ({
    type: PLAYERS__GET_PLAYER_DETAILS_FAILED,
    error
});

export const getPlayerDetailsSuccess = (data: Player): GetPlayerDetailsActionSuccess => ({
    type: PLAYERS__GET_PLAYER_DETAILS_SUCCESS,
    data
});