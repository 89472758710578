import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CountryFlag from "../../../../modules/CountryFlag/CountryFlag";
import { PlayersListProps } from "./types";

const PlayersList = (props: PlayersListProps) => {
    const { players } = props;

    const navigate = useNavigate();
    const playerRowClick = (id: string) => {
        navigate(`/players/${id}`);
    }   

    return (
      <Table striped bordered hover>
      <thead className="align-items-center">
        <tr>
        <th>Zawodnik</th>
          <th>Pozycja</th>
          <th>Rok urodzenia</th>
          <th>Wzrost</th>
          <th>Zasięg (atak/blok)</th>
          <th>Waga</th>
          <th>Numer</th>
        </tr>
      </thead>
      <tbody>
      {players.map(player =>
                  <tr onClick={()=> playerRowClick(player.id)} className={"list-element " && player.didHeChangeClubDuringSeason ?  "unactive-player" : ""}>
          <td>
            <div className='d-flex align-items-center'>
                <CountryFlag countryCode={player.countryCode} />
              <div className='ms-3'>
                <p className='fw-bold mb-1'>{player.name} {player.surname}</p>
                <p className='mb-0'>{player.country}</p>
              </div>
            </div>
          </td>
          <td>
            <p className='fw-normal mb-1'>{player.position}</p>
          </td>
              <td>
                  <p className='mb-0'>{player.birthdayYear !== 1900 ? player.birthdayYear : "-"}</p>
          </td>
          <td>
            <p className='mb-0'>{player.height !== 0 ? player.height : "-"}</p>
          </td>
          <td>
          <p className='mb-0'>{player.attackRange !== 0 ? player.attackRange : "-"} / {player.blockRange !== 0 ? player.blockRange : "-"}</p>
          </td>
          <td>
          <p className='mb-0'>{player.weight !== 0 ? player.weight : "-"}</p>
          </td>
          <td>
          <p className='fw-normal mb-1'>{player.shirtNumber !== 0 ? player.shirtNumber : "-"}</p>
          </td>
        </tr>
            )}
      </tbody>
    </Table>
    );
}

export default PlayersList;