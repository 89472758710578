import produce from "immer";
import { RequestStatus } from "../../../api/types";
import { GetClubsSuccessAction } from "./actions";
import { CLUBS__GET_CLUBS_FAILED, CLUBS__GET_CLUBS_STARTED, CLUBS__GET_CLUBS_SUCCESS } from "./constants";

import { Club } from "./types";

interface StateType {
    data: Club[],
    status: RequestStatus
}

const initialState: StateType = {
    data: [],
    status: RequestStatus.Idle
}

const reducer = produce((state, action) => {
    switch(action.type) {
        case CLUBS__GET_CLUBS_STARTED:
            state.status = RequestStatus.InProgress;
            break;
        case CLUBS__GET_CLUBS_SUCCESS:
            state.status = RequestStatus.Success;
            state.data = (action as GetClubsSuccessAction).data;
            break;
        case CLUBS__GET_CLUBS_FAILED:
            state.status = RequestStatus.Failed;
            break;
        default:
            return state;
    }
}, initialState);

export default reducer;