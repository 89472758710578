import { SendMessageRequest } from "../modules/ContactForm/types";
import client from "./client";
import { GetClubDetailsResponse, GetClubsResponse, GetCoachDetailsResponse, GetCoachesResponse, GetLeagueDetailsResponse, GetLeaguesResponse, GetPlayerDetailsResponse, GetPlayersResponse } from "./responses";

class api {

    //Leagues
    static leaguesList = () => client.get<GetLeaguesResponse>(`/leagues`);
    static leagueDetails = (season: string, id: string) => client.get<GetLeagueDetailsResponse>(`/leagues/${season}/${id}`);

    //Clubs
    static clubsList = () => client.get<GetClubsResponse>(`/clubs`);
    static clubsSearch = (searchPart: string) => client.get<GetClubsResponse>(`/clubs/search/${searchPart}`);
    static clubsForCountry = (countryCode: string) => client.get<GetClubsResponse>(`/clubs/country/${countryCode}`);
    static clubDetails = (season: string, id: string) => client.get<GetClubDetailsResponse>(`/clubs/${season}/${id}`);

    //Players
    static playersList = () => client.get<GetPlayersResponse>(`/players`);
    static playersSearch = (searchPart: string) => client.get<GetPlayersResponse>(`/players/search/${searchPart}`);
    static playersForTheCountry = (countryCode: string) => client.get<GetPlayersResponse>(`/players/country/${countryCode}`);
    static playersForTheClub = (clubId: string) => client.get<GetPlayersResponse>(`/players/club/${clubId}`);
    static playerDetails = (id: string) => client.get<GetPlayerDetailsResponse>(`/players/${id}`);
    static playersTodayBirthday = () => client.get<GetPlayersResponse>(`/players/birthday`);

    //Coaches
    static coachesList = () => client.get<GetCoachesResponse>(`/coaches`);
    static coachesSearch = (searchPart: string) => client.get<GetCoachesResponse>(`/coaches/search/${searchPart}`);
    static coachesForTheCountry = (countryCode: string) => client.get<GetCoachesResponse>(`/coaches/country/${countryCode}`);
    static coachesForTheClub = (clubId: string) => client.get<GetCoachesResponse>(`/coaches/club/${clubId}`);
    static coachDetails = (id: string) => client.get<GetCoachDetailsResponse>(`/coaches/${id}`);

    //ContactForm
    static sendMessage = (request: SendMessageRequest) => client.post(`/emails/sendMessage`, request);

}

export default api;