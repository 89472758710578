import { ErrorAction, IAction, VoidAction } from "../../../app/types";
import { CLUBS__GET_CLUBS, CLUBS__GET_CLUBS_FAILED, CLUBS__GET_CLUBS_STARTED, CLUBS__GET_CLUBS_SUCCESS } from "./constants";

import { Club } from "./types";

export interface GetClubsAction extends IAction {};

export interface GetClubsSuccessAction extends IAction {
    data: Club[];
};

export const getClubs = (): VoidAction => ({
    type: CLUBS__GET_CLUBS
});

export const getClubsStarted = (): VoidAction => ({
    type: CLUBS__GET_CLUBS_STARTED
});

export const getClubsFailed = (error: string): ErrorAction => ({
    type: CLUBS__GET_CLUBS_FAILED,
    error
});

export const getClubsSuccess = (data: Club[]): GetClubsSuccessAction => ({
    type: CLUBS__GET_CLUBS_SUCCESS,
    data
});