import produce from "immer";
import { RequestStatus } from "../../../api/types";
import { GetCoachesSuccessAction } from "./actions";
import { COACHES__GET_COACHES_FAILED, COACHES__GET_COACHES_STARTED, COACHES__GET_COACHES_SUCCESS } from "./constants";

import { Coach } from "./types";

interface StateType {
    data: Coach[],
    status: RequestStatus
}

const initialState: StateType = {
    data: [],
    status: RequestStatus.Idle
}

const reducer = produce((state, action) => {
    switch(action.type) {
        case COACHES__GET_COACHES_STARTED:
            state.status = RequestStatus.InProgress;
            break;
        case COACHES__GET_COACHES_SUCCESS:
            state.status = RequestStatus.Success;
            state.data = (action as GetCoachesSuccessAction).data;
            break;
        case COACHES__GET_COACHES_FAILED:
            state.status = RequestStatus.Failed;
            break;
        default:
            return state;
    }
}, initialState);

export default reducer;