import ContactForm from "../../modules/ContactForm/ContactForm";

const Contact = () => {

    return (
        <div className="container mt-3 mb-3">
            <h2>Napisz do nas!</h2>
            <p>
                Chcesz nawiązać współpracę, zasugerować pomysł, znasz brakujące dane zawodników, albo chcesz nawiązać kontakt w jeszcze innym celu? Skorzystaj z poniższego formularza kontaktowego!
            </p>
            <ContactForm />
        </div>
        
    );
  }
  
  export default Contact;
  