import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RequestStatus } from "../../../api/types";
import { genericSearch } from "../../../functions/genericSearch";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import CardElement from "../../../modules/CardElement/CardElement";
import CountryFlag from "../../../modules/CountryFlag/CountryFlag";
import LoadingComponent from "../../../modules/LoadingComponent/LoadingComponent";
import SearchInput from "../../../modules/SearchInput/SearchInput";
import { getCoaches } from "./actions";
import { Coach } from "./types";


const Coaches = () => {
    const dispatch = useAppDispatch();
    const coaches = useAppSelector(state => state.coaches.data);
    const status = useAppSelector(state => state.coaches.status);

    const [query, setQuery] = useState<string>("");

    useEffect(() => {
      dispatch(getCoaches())
    }, [dispatch]);

    const filterCoaches = coaches
        .filter((coach) =>
            genericSearch<Coach>(coach, ["name", "surname", "country"], query)
    );

    if (status === RequestStatus.InProgress) return <LoadingComponent />;
    else return (
      <>
        <SearchInput onChangeSearchQuery={(query) => setQuery(query)} placeholder="Wyszukaj trenera po nazwie lub kraju..." />
          <Row>
          {filterCoaches.map(coach =>
            <CardElement countryCode={coach.countryCode} link={`/coaches/${coach.id}`} name={`${coach.name} ${coach.surname}`} />
                )}
          </Row> 
      </>
      
    )
}

export default Coaches;