import { ErrorAction, IAction, VoidAction } from "../../../app/types";
import { PLAYERS__GET_PLAYERS, PLAYERS__GET_PLAYERS_FAILED, PLAYERS__GET_PLAYERS_STARTED, PLAYERS__GET_PLAYERS_SUCCESS } from "./constants";

import { Player } from "./types";

export interface GetPlayersAction extends IAction {};

export interface GetPlayersSuccessAction extends IAction {
    data: Player[];
};

export const getPlayers = (): VoidAction => ({
    type: PLAYERS__GET_PLAYERS
});

export const getPlayersStarted = (): VoidAction => ({
    type: PLAYERS__GET_PLAYERS_STARTED
});

export const getPlayersFailed = (error: string): ErrorAction => ({
    type: PLAYERS__GET_PLAYERS_FAILED,
    error
});

export const getPlayersSuccess = (data: Player[]): GetPlayersSuccessAction => ({
    type: PLAYERS__GET_PLAYERS_SUCCESS,
    data
});