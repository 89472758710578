import { ErrorAction, IAction, VoidAction } from "../../../app/types";
import { CLUBS__GET_CLUB_DETAILS, CLUBS__GET_CLUB_DETAILS_FAILED, CLUBS__GET_CLUB_DETAILS_STARTED, CLUBS__GET_CLUB_DETAILS_SUCCESS } from "./constants";


import { Club } from "./types";

export interface GetClubDetailsAction extends IAction {
    season: string,
    id: string
};

export interface GetClubDetailsActionSuccess extends IAction {
    data: Club;
};

export const getClubDetails = (season: string, id: string): GetClubDetailsAction => ({
    type: CLUBS__GET_CLUB_DETAILS,
    season,
    id
});

export const getClubDetailsStarted = (): VoidAction => ({
    type: CLUBS__GET_CLUB_DETAILS_STARTED
});

export const getClubDetailsFailed = (error: string): ErrorAction => ({
    type: CLUBS__GET_CLUB_DETAILS_FAILED,
    error
});

export const getClubDetailsSuccess = (data: Club): GetClubDetailsActionSuccess => ({
    type: CLUBS__GET_CLUB_DETAILS_SUCCESS,
    data
});