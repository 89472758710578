import { Card, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import CountryFlag from '../CountryFlag/CountryFlag';
import { CardElementProps } from './types';

const CardElement = (props: CardElementProps) => {

    const { countryCode, link, name } = props;

        return (
            <Col xs={12} md={6} lg={3} className="card-element">
                <NavLink to={link} className="link">
                <Card>
                <Card.Body>
                  <Card.Text>
                  <div className="title">
                    <div className="flag">
                      <CountryFlag countryCode={countryCode} />
                    </div>
                    <div className="name">
                      {name}
                    </div>
                  </div>
                  </Card.Text>
                </Card.Body>
              </Card>
                </NavLink>
              </Col>
        );
}

export default CardElement;